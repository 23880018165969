<template>
  <div class="container">
    <div class="bread-crumb my-3">
      <a
        class="text-primary"
        href="javascript:void(0);"
        @click="$router.push('/').catch(() => {})"
        >HOME</a
      >
      >
      <a
        class="text-primary"
        href="javascript:void(0);"
        @click="$router.push('/parksearchresults').catch(() => {})"
        >SEARCH RESULTS</a
      >
      >
      <a
        class="text-primary"
        href="javascript:void(0);"
        @click="
          $router.push(`/location/${reservation.locationId}`).catch(() => {})
        "
        >{{ reservation.locationName.toUpperCase() }}</a
      >
      >
      <a
        @click="$router.push('/create-reservation').catch(() => {})"
        href="javascript:void(0);"
        class="text-primary"
        >CREATE RESERVATION</a
      >
      > REVIEW RESERVATION
    </div>
    <div class="row">
      <div class="col-md-8 col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title mt-2 mb-0">Personal Information</div>
          </div>
          <div class="card-body">
            <p>{{ reservation.firstName }} {{ reservation.lastName }}</p>
            <p class="mb-0">{{ reservation.street1 }}</p>
            <p v-if="reservation.street2 !== ''" class="mb-0">
              {{ reservation.street2 }}
            </p>
            <p>
              {{ reservation.city }}, {{ reservation.state }}
              {{ reservation.zipCode }}
            </p>
            <p class="mb-0">
              Phone: {{ reservation.phone | formatPhoneNumber }}
            </p>
            <p v-if="reservation.alternatePhone" class="mb-0">
              Alternate Phone:
              {{ reservation.alternatePhone | formatPhoneNumber }}
            </p>
            <p>Email: {{ reservation.email }}</p>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <div class="card-title mt-2 mb-0">Occupancy Information</div>
          </div>
          <div class="card-body">
            <p>
              Primary Occupant: {{ reservation.firstName }}
              {{ reservation.lastName }}
            </p>
            <p class="mb-0">Occupants: {{ reservation.numberOfPeople }}</p>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <div class="card-title mt-2 mb-0">Equipment Information</div>
          </div>
          <div class="card-body">
            <p>Number of Vehicles: {{ reservation.numberOfVehicles }}</p>
            <p>
              Equipment Type:
              {{
                reservation.spot.spotEquipmentTypes.find(
                  x => x.equipmentType.id == reservation.equipmentType
                ).equipmentType.name
              }}
            </p>
            <p
              v-if="
                reservation.equipmentLength &&
                  +reservation.equipmentLength !== 0
              "
            >
              Equipment Length (ft): {{ reservation.equipmentLength }}
            </p>
          </div>
        </div>
        <!-- <PurchaseAddOns mode="view" :addOns="reservation.addOns" /> -->
      </div>

      <div class="col-md-4 col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title mt-2 mb-0 info-label">
              Reservation Site Information
            </div>
          </div>
          <div class="card-body">
            <div class="form-row justify-content-center">
              <div class="col col-6 form-group text-left">
                <div class="info-label">Park</div>
              </div>
              <div class="col col-6 form-group text-right">
                <div class="info-label">{{ reservation.locationName }}</div>
              </div>
            </div>
            <div class="form-row justify-content-center">
              <div class="col col-6 form-group text-left">
                <div class="info-label">Site</div>
              </div>
              <div class="col col-6 form-group text-right">
                <div class="info-label">{{ reservation.spot.name }}</div>
              </div>
            </div>
            <div class="form-row justify-content-center">
              <div class="col col-6 form-group text-left">
                <div class="info-label">Loop</div>
              </div>
              <div class="col col-6 form-group text-right">
                <div class="info-label">{{ reservation.spot.loop.name }}</div>
              </div>
            </div>
            <div class="form-row justify-content-center" v-if="!isDayUse">
              <div class="col col-6 form-group text-left">
                <div class="info-label">Number of Nights</div>
              </div>
              <div class="col col-6 form-group text-right">
                <div class="info-label">{{ reservation.numberOfNights }}</div>
              </div>
            </div>
            <hr class="mt-0" />
            <div class="form-row justify-content-center">
              <div class="col col-6 form-group text-left">
                <div class="info-label">Check In</div>
              </div>
              <div class="col col-6 form-group text-right">
                {{ reservation.startDate | formatDate("MM-DD-YYYY") }}
                {{ reservation.spot.checkInTime }}
              </div>
            </div>
            <div class="form-row justify-content-center">
              <div class="col col-6 form-group text-left">
                <div class="info-label">Check Out</div>
              </div>
              <div class="col col-6 form-group text-right">
                {{ reservation.endDate | formatDate("MM-DD-YYYY") }}
                {{ reservation.spot.checkOutTime }}
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <div class="card-title mt-2 mb-0 info-label">Amount Due</div>
          </div>
          <div class="card-body">
            <div
              v-for="(fee, index) in fees"
              :key="index"
              class="form-row justify-content-center"
            >
              <div class="col col-6 form-group text-left">
                <div class="info-label">{{ fee.name }}</div>
              </div>
              <div class="col col-6 form-group text-right">
                <div class="info-label">
                  <span
                    v-if="fee.feeType == 'ParkUse'"
                    v-b-popover.hover.html.top="
                      getFeeBreakdown(fee.feeBreakdown)
                    "
                    class="text-primary"
                    >{{ (fee.amount * fee.quantity) | formatCurrency }}</span
                  >
                  <span v-else>{{
                    (fee.amount * fee.quantity) | formatCurrency
                  }}</span>
                </div>
              </div>
            </div>
            <div v-if="addOnFee" class="form-row justify-content-center">
              <div class="col col-6 form-group text-left">
                <div class="info-label">Firewood Bundle(s)</div>
              </div>
              <div class="col col-6 form-group text-right">
                <div class="info-label">{{ addOnFee | formatCurrency }}</div>
              </div>
            </div>
            <hr class="mt-0" />
            <div class="form-row justify-content-center">
              <div class="col col-6 form-group text-left">
                <div class="info-label">
                  <b>Pay by Credit/Debit card<sup>1</sup></b>
                </div>
              </div>
              <div class="col col-6 form-group text-right">
                <div class="info-label">
                  <b>{{ creditCardAmount | formatCurrency }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!convenienceFee">
          <span class="fine-print">
            <sup>1</sup> CC Payment option includes
            {{ convenienceFee.creditCardPercentage }}% (minimum ${{
              convenienceFee.creditCardMinAmount
            }}) payment processing fee.
          </span>
        </div>
        <Alert
          v-if="
            reservation.spot.product &&
              reservation.spot.product.productClassification
                .applyMessageToWebsite
          "
          :alert="{
            htmlMessage: reservation.spot.product.productClassification.message,
            type: 'alert-primary'
          }"
          class="mb-2"
        />
      </div>
    </div>
    <div class="row">
      <div class="col col-12">
        <div class="alert-header">ALERTS AND IMPORTANT INFORMATION</div>
        <div class="policy-section" tabindex="0">
          <div class="info-label">PARK</div>
          <ul>
            <li>
              Shelter Reservations are non-refundable unless the area is closed
              by the Department, in this circumstance the Site Manager will
              contact you prior to your reservation date to cancel and issue
              your refund.
            </li>
            <li>
              Camping fees are refundable if cancellation notification is
              received no later than 3 days prior to arrival.
            </li>
            <li>
              A responsible adult (18 years of age or older) from the camping
              party must register for the party and thereby acknowledge
              compliance with the rules and regulations of the park for the
              party.
            </li>
            <li>
              For specific park information or questions regarding your
              reservation, please call the Park Office.
            </li>
            <li>
              Please click
              <a>here</a> to read the rules & regulations for camping at all
              State of Illinois campgrounds.
            </li>
          </ul>
          <div class="info-label">SITE</div>
          <br />
          <div class="info-label">CANCELLATION POLICY:</div>
          <div class="info-label">CAMPSITES, CABINS:</div>
          <p>
            Cancellations made prior to the minimum booking window for the
            facility will be provided a full refund minus the non-refundable
            $5.00 reservation fee. Cancellations made inside the minimum
            reservation window, but prior to arrival, will be charged the first
            nights campsite or cabin use fee as well as the $5.00 reservation
            fee. All remaining funds will be refunded.
          </p>
          <p>
            Cancellations (or early departures) made after the scheduled arrival
            date will be charged for the nights that have passed (or the minimum
            stay requirement, whichever is greater), plus one additional nights
            campground or cabin use fee and the $5.00 reservation fee. Any
            remaining funds will be refunded.
          </p>
          <p>
            No-shows will be charged the full amount of the campsite/cabin
            reservation fee and the $5.00 reservation fee, without refund.
          </p>
          <div class="info-label">SHELTERS:</div>
          <p>
            The reservation fee for Shelters is $50 for each day the shelter is
            reserved. All Shelter fees are non-refundable unless the area is
            closed by the Department. Upon vacating the site, shelter users are
            required to remove all personal belongings and place all trash in
            the appropriate receptacles located at the site. Failure to clean up
            the area after you leave will result in a disposal fee of $50.
            Failure to pay the disposal fee will result in denial of future
            reservations until the fee is paid in full. Shelter Rentals: Shelter
            rentals are for day use only, not overnight stays.
          </p>
          <div
            class="info-label text-uppercase"
            v-if="reservation.adaRequirementAcknowledged"
          >
            This spot is reservable only by or for persons with disabilities. By
            answering yes to this question, I am attesting that this spot is
            being reserved for a person(s) with disabilities.
          </div>
          <br />
          <div class="info-label" v-if="alerts && alerts.length > 0">
            ALERTS:
          </div>
          <p v-for="alert in alerts" :key="alert.id" v-html="alert.message"></p>
        </div>
        <div class="form-row justify-content-center">
          <div class="col col-12 form-group text-left">
            <label>
              <input
                type="checkbox"
                :checked="alertsHaveBeenRead"
                @change="toggleAlertsRead"
                class="mr-2"
              />
              Yes, I have read and understood this important information.
              <span class="error">*</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <button
      class="btn btn-primary mr-2"
      type="button"
      :disabled="!alertsHaveBeenRead || addingToCart"
      @click="onAddToCart"
    >
      CONFIRM
    </button>

    <button class="btn btn-cancel mr-2" type="button" @click="goBack">
      EDIT RESERVATION
    </button>
    <p class="mt-3">
      <span style="color: red">*</span> Indicates a required field
    </p>
  </div>
</template>

<script>
import CartService from "@/services/CartService.js";
import PricingService from "@/services/PricingService.js";
// import PurchaseAddOns from "@/components/reservation/PurchaseAddOns.vue";
import AlertService from "@/services/AlertService.js";
import moment from "moment";
import Alert from "@/components/alert/Alert.vue";

export default {
  name: "ReviewReservation",
  title: "Review Reservation",
  components: {
    Alert
  },
  data() {
    return {
      fees: [],
      convenienceFee: null,
      feeTotal: 0,
      alertsHaveBeenRead: false,
      addingToCart: false,
      alerts: []
    };
  },
  beforeCreate() {
    window.scrollTo(0, 0);
  },
  methods: {
    getFeeBreakdown(feeBreakdown) {
      if (feeBreakdown == null) {
        return "";
      }
      let fees = "";
      feeBreakdown.forEach(fee => {
        let amount = parseFloat(fee.amount);
        let amountString = amount.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2
        });
        fees += `${moment
          .utc(fee.date)
          .format("ddd MM/DD/YYYY")} - ${amountString}<br/>`;
      });
      return fees;
    },
    toggleAlertsRead() {
      this.alertsHaveBeenRead = !this.alertsHaveBeenRead;
    },
    loadSpotAlerts() {
      const alertService = new AlertService(this.tenantId);
      const reservation = this.$store.getters["transaction/reservation"];
      alertService.getWebAlerts(reservation.locationId).then(response => {
        this.alerts = response.filter(alert => {
          return (
            alert.alertSpots.filter(as => as.spotId == reservation.spotId)
              .length > 0
          );
        });
      });
    },
    goBack() {
      this.$store.commit("transaction/setReservationInProgress", false);
      this.$router.push("/create-reservation").catch(() => {});
    },
    async getFees() {
      const tenantId = this.tenantId;
      const reservation = this.$store.getters["transaction/reservation"];
      try {
        const pricingService = new PricingService(tenantId);
        const {
          spotId,
          startDate,
          endDate,
          numberOfPeople,
          numberOfVehicles,
          discounts
        } = reservation;
        const feeResponse = await pricingService.getFees(
          {
            customerId: reservation.customerId,
            spotId,
            startDate,
            endDate,
            numberOfAdults: numberOfPeople,
            numberOfVehicles,
            salesChannel: 1,
            discounts
          },
          0
        );
        if (feeResponse && feeResponse.statusCode === "Success") {
          this.fees = [
            ...feeResponse.data.filter(x => x.feeType !== "Convenience")
          ];
          this.convenienceFee = feeResponse.data.find(
            x => x.feeType === "Convenience"
          );
          this.computeTotal(this.fees);
        } else {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Fees weren't found",
            layer: "public"
          });
        }
      } catch (err) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "public"
        });
      }
    },
    computeTotal(fees) {
      fees.forEach(fee => {
        this.feeTotal += fee.amount * fee.quantity;
      });
      if (this.addOnFee) {
        this.feeTotal += this.addOnFee;
      }
    },
    async onAddToCart() {
      this.$store.commit("auth/setLoading", true);
      this.addingToCart = true;
      this.addToCart()
        .then(response => {
          if (response && response.statusCode === "Success") {
            this.$store.dispatch("transaction/incrementNumberOfItemsInCart");
            this.$store.dispatch("transaction/clearReservation");
            this.$router.push("shopping-cart");
          } else if (response?.statusCode == "Failure") {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: response.messages[0],
              layer: "public"
            });
          }
        })
        .finally(() => {
          this.addingToCart = false;
          this.$store.commit("auth/setLoading", false);
        });
    },
    async addToCart() {
      const tenantId = this.$store.getters["tenant/tenantId"];
      const request = this.mapReservationToRequest(this.reservation);
      try {
        const cartService = new CartService(tenantId);

        //Check if reservation is already in the cart
        const cart = await cartService.getUserCart();

        const cartItem = cart.items.find(
          item => item.productItemId == this.reservation.id
        );
        if (cartItem) {
          //Remove reservation first
          await cartService.removeItemFromCart(cartItem.id, false);
          this.$store.dispatch("transaction/decrementNumberOfItemsInCart");
        }

        return cartService.addReservationToCart(request);
      } catch (err) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "public"
        });
      }
    },
    mapReservationToRequest(reservation) {
      const tenantId = this.$store.getters["tenant/tenantId"];
      const {
        id,
        spotId,
        startDate,
        endDate,
        numberOfPeople,
        numberOfVehicles,
        equipmentLength,
        equipmentType,
        firstName,
        lastName,
        street1,
        street2,
        city,
        state,
        zipCode,
        email,
        alternatePhone,
        phone,
        lockCode,
        discounts,
        customerId,
        isCustomerPrimaryOccupant,
        adaRequirementAcknowledged,
        disableDiscounts
      } = reservation;
      const request = {
        reservationId: id,
        tenantId,
        spotId,
        startDate,
        endDate,
        numberOfAdults: numberOfPeople,
        numberOfVehicles,
        discounts: [...discounts],
        equipmentLength: +equipmentLength,
        equipmentTypeId: equipmentType,
        isCustomerPrimaryOccupant: isCustomerPrimaryOccupant,
        primaryOccupantFirstName: firstName,
        primaryOccupantLastName: lastName,
        primaryOccupantStreet1: street1,
        primaryOccupantStreet2: street2,
        primaryOccupantCity: city,
        primaryOccupantState: state,
        primaryOccupantZipcode: zipCode,
        primaryOccupantCountry: "USA",
        primaryOccupantEmail: email,
        primaryOccupantHomePhone: alternatePhone,
        primaryOccupantMobilePhone: phone,
        lockCode,
        customerId,
        adaRequirementAcknowledged,
        disableDiscounts
      };
      if (this.reservation.addOns) {
        request.addOns = [];
        for (const prop in this.reservation.addOns) {
          if (this.reservation.addOns[prop]) {
            request.addOns.push({
              id: prop,
              quantity: this.reservation.addOns[prop]
            });
          }
        }
      }

      return request;
    }
  },
  mounted() {
    this.getFees();
    this.loadSpotAlerts();
  },
  computed: {
    reservation() {
      return this.$store.getters["transaction/reservation"];
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    addOnFee() {
      let addOnFee = null;
      for (const addon in this.reservation.addOns) {
        //TODO: Update this hardcoded dollar amount
        addOnFee = this.reservation.addOns[addon] * 5;
      }
      return addOnFee;
    },
    isDayUse() {
      if (!this.reservation.spot || !this.reservation.spot.product) return null;

      return this.reservation.spot.product.productType.bookingType.id === 2; //Day Use
    },
    creditCardAmount() {
      if (this.feeTotal) {
        return this.feeTotal + this.convenienceFee.creditCardAmount;
      }
      return 0;
    }
  }
};
</script>

<style scoped>
.info-label {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
}
.alert-header {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
  color: #000;
  font-size: 1.3125rem;
}
.fine-print {
  font-size: smaller;
}
</style>
